@import 'scss/settings/variables';
$prefix-cls: date-picker;

$background-color: white;

$input-placeholder-color: #707070;

$border-color-focused: #3182ce;

$primary: #3182ce;
$light-primary: #ddf2ff;
$dash-primary: #63b3ed;
$secondary: #324562;
$hover-bg: #f5f5f5;

$py: 4px;
$screen2xl: 1820px;
$screen-md: 767px;

@mixin placeholder($color: $input-placeholder-color) {
  // Firefox
  &::-moz-placeholder {
    opacity: 0.5; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  &::placeholder {
    color: $color;
    font-family: 'Roboto Italic';
    opacity: 0.5;
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}

.#{$prefix-cls} {
  display: inline-flex;
  border-width: 1px;
  border-style: solid;
  padding: 0 16px;
  border-radius: 10px;
  width: 100%;

  &.invalid {
    border-color: $danger;
    box-shadow: $danger 0px 0px 0px 1px;
  }
  &.#{$prefix-cls}-focused {
    border-color: $border-color-focused;
    box-shadow: $border-color-focused 0px 0px 0px 1px;
  }
  &-panel {
    background: $background-color;
    display: inline-block;
    vertical-align: top;
  }

  &-disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  // ===================== Shared Panel =====================
  &-decade-panel,
  &-year-panel,
  &-month-panel,
  &-week-panel,
  &-date-panel,
  &-time-panel {
    display: flex;
    flex-direction: column;
    width: 280px;
    table {
      text-align: center;
      border-collapse: collapse;
    }
  }
  @media only screen and (max-width: $screen-md) {
    &-decade-panel,
    &-year-panel,
    &-month-panel,
    &-week-panel,
    &-date-panel,
    &-time-panel {
      width: 240px;
    }
  }

  // Header
  &-header {
    display: flex;
    padding: 0 8px;
    border-bottom: 1px solid #f0f0f0;
    & > button {
      min-width: 1.6em;
      font-size: 14px;

      &:hover {
        color: #000000d9;
      }
    }

    > * {
      flex: none;
    }

    &-view {
      flex: auto;
      text-align: center;
      font-weight: 600;
      line-height: 40px;
      button {
        color: inherit;
        border: 0;
        padding: 0;
        font-weight: inherit;
        line-height: 40px;
        &:not(:first-child) {
          margin-left: 8px;
        }

        &:hover {
          color: #3182ce;
        }
      }
    }

    button {
      padding: 0;
      line-height: 40px;
      background: 0 0;
      border: 0;
      cursor: pointer;
      transition: color 0.3s;
    }

    .#{$prefix-cls}-prev-icon,
    .#{$prefix-cls}-next-icon,
    .#{$prefix-cls}-super-prev-icon,
    .#{$prefix-cls}-super-next-icon {
      position: relative;
      display: inline-block;
      width: 7px;
      height: 7px;
    }

    // prev action
    .#{$prefix-cls}-prev-icon,
    .#{$prefix-cls}-super-prev-icon {
      transform: rotate(-45deg);
    }
    // next action
    .#{$prefix-cls}-next-icon,
    .#{$prefix-cls}-super-next-icon {
      transform: rotate(135deg);
    }

    // super icon
    .#{$prefix-cls}-super-prev-icon,
    .#{$prefix-cls}-super-next-icon {
      &::after {
        position: absolute;
        top: 4px;
        left: 4px;
        display: inline-block;
        width: 7px;
        height: 7px;
        border: 0 solid currentColor;
        border-width: 1.5px 0 0 1.5px;
        content: '';
      }
    }

    .#{$prefix-cls}-prev-icon,
    .#{$prefix-cls}-next-icon,
    .#{$prefix-cls}-super-prev-icon,
    .#{$prefix-cls}-super-next-icon {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        display: inline-block;
        width: 7px;
        height: 7px;
        border: 0 solid currentColor;
        border-width: 1.5px 0 0 1.5px;
        content: '';
      }
    }
  }

  // Content
  &-content {
    th {
      height: 30px;
      line-height: 30px;
    }

    th,
    td {
      position: relative;
      min-width: 24px;
      font-weight: 400;
    }
  }

  &-cell {
    color: #aaa;
    padding: 3px 0;
    color: #00000040;
    cursor: pointer;
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      z-index: 1;
      height: 24px;
      transform: translateY(-50%);
      transition: all 0.3s;
      content: '';
    }

    &-in-view {
      color: #333;
    }
    &-disabled {
      color: #00000040;
      pointer-events: none;
      &::before {
        background: rgba(0, 0, 0, 0.04);
      }
    }

    &-inner {
      position: relative;
      z-index: 2;
      display: inline-block;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      border-radius: 2px;
      transition: background 0.3s, border 0.3s;
    }

    &-in-range > &-inner {
      background: #ebf8ff;
      // color: ;
    }
    &-range-hover-start,
    &-range-hover-end,
    &-range-hover {
      position: relative;
      &:not(.date-picker-cell-in-range),
      &:not(.date-picker-cell-range-start),
      &:not(.date-picker-cell-range-end) {
        &::after {
          content: '';
          position: absolute;
          top: 2px;
          bottom: 2px;
          left: 0;
          right: 0;
          border: 1px dashed $dash-primary;
          border-left: 0;
          border-right: 0;
          pointer-events: none;
        }
      }
    }
    &-range-hover-start {
      &:not(.date-picker-cell-in-range),
      &:not(.date-picker-cell-range-start),
      &:not(.date-picker-cell-range-end) {
        &::after {
          border-left: 1px dashed $dash-primary !important;
          left: 5px;
        }
      }
    }
    &-range-hover-end {
      &:not(.date-picker-cell-in-range),
      &:not(.date-picker-cell-range-start),
      &:not(.date-picker-cell-range-end) {
        &::after {
          border-right: 1px dashed $dash-primary !important;
          right: 5px;
        }
      }
    }

    &-today > &-inner {
      border: 1px solid $primary;
    }
    &-range-start > &-inner,
    &-range-end > &-inner,
    &-selected > &-inner {
      background: $primary;
      color: white;
    }
  }

  &-footer,
  &-picker-footer {
    border-top: 1px solid #f0f0f0;
    padding: 6px 0;
    display: flex;
    justify-content: center;
    a {
      color: $primary;
      cursor: pointer;
    }
  }

  &-ranges {
    margin: 0;
    overflow: hidden;
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    > li {
      display: inline-block;
    }
  }

  // &-now {
  //   padding-right: 10px;
  // }

  // &-ok {
  //   float: right;
  //   button {
  //     color: #3182ce;
  //     background-color: unset;
  //     font-weight: normal;
  //   }
  //   button:hover {
  //     background-color: unset;
  //   }
  // }

  // ================== Year & Month Panel ==================
  &-year-panel,
  &-month-panel {
    .#{$prefix-cls}-cell-inner {
      width: 80px;
      @media only screen and (max-width: $screen-md) {
        & {
          width: 60px;
        }
      }
    }

    .#{$prefix-cls}-body {
      padding: 8px 12px;
    }
  }

  &-decade-panel {
    .#{$prefix-cls}-cell-inner {
      width: 80px;
      @media only screen and (max-width: $screen-md) {
        & {
          width: 60px;
        }
      }
    }
    .#{$prefix-cls}-body {
      padding: 8px 12px;
    }
  }

  // ====================== Week Panel ======================
  &-week-panel {
    &-row {
      &:hover {
        .#{$prefix-cls}-cell {
          background: red;
        }
      }

      &-selected {
        .#{$prefix-cls}-cell {
          background: rgba(0, 0, 255, 0.3);
        }
      }
    }

    .#{$prefix-cls}-cell,
    .#{$prefix-cls}-cell-inner {
      width: 20px;
    }

    .#{$prefix-cls}-cell-week {
      font-size: 12px;
      color: #999;
      font-weight: bold;
    }

    .#{$prefix-cls}-cell:hover > .#{$prefix-cls}-cell-inner,
    .#{$prefix-cls}-cell-selected > .#{$prefix-cls}-cell-inner {
      background: transparent;
    }
  }

  // ====================== Date Panel ======================
  &-date-panel {
    .#{$prefix-cls}-cell-inner {
      width: 20px;
    }

    .#{$prefix-cls}-body {
      padding: 8px 12px;
    }
    .#{$prefix-cls}-content th {
      width: 36px;
    }
  }

  // ====================== Time Panel ======================
  &-time-panel {
    width: auto;
    .#{$prefix-cls}-content {
      display: flex;
      // flex-grow: 1;
      max-height: 228px;
      & > *:not(:last-child) {
        border-right: 1px solid #f0f0f0;
      }
    }

    &-column {
      flex: none;
      text-align: left;
      list-style: none;
      margin: 0;
      padding: 0 0 180px 0;
      overflow-y: hidden;
      overflow-x: hidden;
      width: 50px;
      transition: background 0.3s;

      @media only screen and (max-width: $screen-md) {
        & {
          width: 40px;
        }
      }

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #bdbdbd;
      }

      &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
      }

      &-active {
        background: rgba(0, 0, 255, 0.1);
      }

      &:hover {
        overflow-y: auto;
      }

      > li {
        padding: 0;
        margin: 0;
        cursor: pointer;
        &:hover {
          background: $hover-bg;
        }
        &.date-picker-time-panel-cell-selected {
          background: $light-primary;
        }
        &.date-picker-time-panel-cell-disabled {
          color: #00000040;
        }
        .#{$prefix-cls}-time-panel-cell-inner {
          padding: 0 0 0 12px;
          margin: 0;
          height: 24px;
          line-height: 24px;
          display: block;
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  // ====================== Date Time =======================
  &-datetime-panel {
    display: flex;

    .#{$prefix-cls}-time-panel {
      border-left: 1px solid #f0f0f0;

      .date-picker-content {
        & > *:not(:last-child) {
          border-right: 1px solid #f0f0f0;
        }
      }
    }

    .#{$prefix-cls}-date-panel,
    .#{$prefix-cls}-time-panel {
      transition: opacity 0.3s;
    }

    // Keyboard
    &-active {
      .#{$prefix-cls}-date-panel,
      .#{$prefix-cls}-time-panel {
        opacity: 0.3;

        &-active {
          opacity: 1;
        }
      }
    }
  }

  // ======================== Input =========================
  &.size {
    &-md {
      input {
        line-height: 48px;
      }
    }
    &-sm {
      input {
        line-height: 40px;
      }
    }
  }
  &-input {
    position: relative;
    display: inline-flex;
    width: 100%;

    input {
      font-size: 1rem;
      width: 100%;
      outline: none;
      font-family: 'Roboto Bold';
      background: unset;

      line-height: 38px;
      @include placeholder;
    }

    @media only screen and (min-width: $screen2xl) {
      input {
        line-height: 46px;
      }
    }

    &-placeholder {
      > input {
        color: $input-placeholder-color;
      }
    }
  }
  &-suffix {
    align-self: center;
    margin-left: 4px;
    color: #00000040;
    line-height: 1;
    pointer-events: none;
  }
  &-clear {
    position: absolute;
    top: 50%;
    right: 0;
    color: #00000040;
    line-height: 1;
    background: #fff;
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s, color 0.3s;

    &:hover {
      opacity: 1;
    }
  }

  &.date-picker-range {
    .date-picker-clear {
      right: 16px;
    }
  }

  // ======================= Dropdown =======================
  &-dropdown {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: absolute;
    z-index: 1500;

    @media only screen and (max-width: $screen-md) {
      & {
        font-size: 12px;
      }
    }

    &-range {
      padding: 10px 0;
    }

    &-hidden {
      display: none;
    }

    // Panel
    $arrow-size: 10px;

    &-placement-topLeft,
    &-placement-topRight {
      .#{$prefix-cls}-range-arrow {
        bottom: $arrow-size / 2;
        transform: rotate(135deg);
      }
    }
    &-placement-bottomLeft,
    &-placement-bottomright {
      .#{$prefix-cls}-range-arrow {
        top: $arrow-size / 2;
        transform: rotate(-45deg);
      }
    }

    .#{$prefix-cls}-range-arrow {
      position: absolute;
      width: $arrow-size;
      height: $arrow-size;
      z-index: 1;
      left: $arrow-size;
      margin-left: 10px;
      transition: all 0.3s;
      box-shadow: 2px -2px 6px #0000000f;

      &::before,
      &::after {
        content: '';
        position: absolute;
        box-sizing: border-box;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &::before {
        width: $arrow-size;
        height: $arrow-size;
        border: $arrow-size / 2 solid #f0f0f0;
        border-color: #f0f0f0 #f0f0f0 transparent transparent;
      }
      &::after {
        width: $arrow-size - 2px;
        height: $arrow-size - 2px;
        border: ($arrow-size - 2px) / 2 solid #f0f0f0;
        border-color: $background-color $background-color transparent transparent;
      }
    }
  }

  // ========================================================
  // =                     Range Picker                     =
  // ========================================================
  &-range {
    display: inline-flex;
    position: relative;

    &-wrapper {
      display: flex;
    }

    &-separator {
      padding-left: 4px;
      padding-right: 8px;
      display: flex;
      align-items: center;
    }

    .#{$prefix-cls}-active-bar {
      background: #2c5282;
      bottom: 0;
      height: 3px;
      margin-left: 16px;
      pointer-events: none;
      transition: all 0.3s;
      opacity: 0;
    }

    &.#{$prefix-cls}-focused {
      .#{$prefix-cls}-active-bar {
        opacity: 1;
      }
    }
  }

  &-panel-container {
    display: inline-block;
    vertical-align: top;
    transition: margin 0.3s;
    overflow: hidden;
    vertical-align: top;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    transition: margin 0.3s;
    .#{$prefix-cls}-panel {
      .#{$prefix-cls}-content,
      table {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.date-picker-cell:hover:not(.date-picker-cell-in-view) .date-picker-cell-inner,
.date-picker-cell:hover:not(.date-picker-cell-selected):not(.date-picker-cell-range-start):not(.date-picker-cell-range-end):not(.date-picker-cell-range-hover-start):not(.date-picker-cell-range-hover-end)
  .date-picker-cell-inner {
  background: $hover-bg;
}
