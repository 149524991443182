.pagination {
  display: flex;
  list-style: none;
  padding: 10px 5px;
  & > * {
    // outline: none;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }

  &--md {
    .rc-select {
      width: 120px;
    }
  }
  &--sm {
    .rc-select {
      width: 90px;
    }
  }
}
