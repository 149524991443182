@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Bold';
  src: url('../../assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Italic';
  src: url('../../assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
}
